<template>
  <div class="action-card" v-if=actionData>
    <div class="action-card__image">
      <img :src="actionData.cover">
    </div>
    <div class="action-card__content" :style="{
      backgroundColor: backgroundColor || undefined,
      color: textColor || undefined
    }">
      <mks-text tag="h4" class="action-card__title">{{ actionData.titre }}</mks-text>
      <mks-text tag="p" class="action-card__text">{{ actionData.texte }}</mks-text>
      <nuxt-link :to="actionData.lien" target="_blank" @click="emits('click')">
        <mks-button class="action-card__button" size="small" :style="{
          borderColor: buttonColor || undefined,
          backgroundColor: buttonColor || undefined,
          color: arrowColor || undefined
        }">
          <long-arrow width="35" />
        </mks-button>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup>
import longArrow from '@/assets/icons/arrow-long.svg';

const props = defineProps({
  action: Object,
  backgroundColor: String,
  textColor: String,
  buttonColor: String,
  arrowColor: String
});
const actionData = computed(() => {
  if (props?.action?.value?.data) {
    return props.action.value.data;
  }
  else if (props?.action?.data) {
    return props.action.data;
  }
  return null;
})


const emits = defineEmits(['click'])
</script>

<style lang="scss" scoped>
.action-card {
  position: relative;
  max-width: 400px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: $space-l;

  &__image {

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 400 / 228;
    }
  }

  &__content {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: $space-l;
  }

  &__title {
    font-weight: $font-weight-black;
    font-size: 1.685rem;
    margin-bottom: $space-m;
  }
  &__text {
    margin-bottom: $space-s;
  }

  &__button {
    padding: $space-xs $space-s;
    line-height: 0;
  }
}
</style>